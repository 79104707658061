<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    base-material-card.px-5.py-3(icon='mdi-account-key' title='Herramientas de Contabilidad' style='min-height: 100vh;')
      v-row
        v-col(cols="12" md="6")
          v-card.mx-auto(outlined='')
            v-card-title Convertir .txt a Excel
            v-card-text
              v-row
                v-col(cols="12" style="margin: 20px;")
                  input(type="file" id="i_file" value="" accept=".txt")
                  pre(id="output")
            v-card-actions
              div(style="width: 100%; text-align: center;")
                v-btn(outlined='' rounded='' text='' @click="convertTxtFile" :loading="loadingFile")
                  | TRANSFORMAR
</template>
<script>
  import * as functions from '../../../functions.js'
  export default {
    data: () => ({
      loadingFile: false,
      strTxtData: '',
    }),
    methods: {
      convertTxtFile () {
        let txtConvert = '[{"val":"' + this.strTxtData.toString() + '"}]'
        txtConvert = txtConvert.replace(/\t/g, '&')
        txtConvert = txtConvert.replace('&&', '&')
        txtConvert = txtConvert.replace(/(?:\r\n|\r|\n)/g, '"},{"val":"')
        const jsonSend = JSON.parse(txtConvert)
        const newArrSend = []
        for (let i = 0; i < jsonSend.length; i++) {
          const strSplit = jsonSend[i].val.toString().split('&')
          if (strSplit.length < 2) {
            const strSplitValid = jsonSend[i - 1].val.toString().split('&')
            if (strSplitValid.length > 1) {
              newArrSend.push(jsonSend[i - 1].val + '&' + jsonSend[i].val)
            }
          }
        }
        const newJsonVal = []
        let stringHead = null
        for (let i = 0; i < newArrSend.length; i++) {
          if (i === 0) {
            stringHead = newArrSend[i].toString().split('&')
          } else {
            const stringVals = newArrSend[i].replace('&&', '&').toString().split('&')
            let val = ''
            for (let j = 0; j < stringVals.length; j++) {
              let stringSet = ''
              if (j === 2) {
                stringSet = stringVals[j].toString() + '-'
              } else if (j === 7) {
                stringSet = stringVals[j].toString() + '-'
              } else if (j === 8) {
                stringSet = stringVals[j].toString() + '-'
              } else if (j === 9) {
                stringSet = stringVals[j].toString() + '-'
              } else if (j === 10) {
                stringSet = stringVals[j].replace('.', ',')
              } else {
                stringSet = stringVals[j]
              }
              if (j === (stringVals.length - 1)) {
                val = val + '"' + stringHead[j] + '": "' + stringSet + '"'
              } else {
                val = val + '"' + stringHead[j] + '": "' + stringSet + '",'
              }
            }
            newJsonVal.push(JSON.parse('{' + val + '}'))
          }
        }
        functions.JSONToCSVConvertor(newJsonVal, 'Excel-file', true)
      },
    },
    mounted () {
      const self = this
      document.getElementById('i_file').addEventListener('change', readFileAsString)
      function readFileAsString () {
        this.loadingFile = true
        var files = this.files
        if (files.length === 0) {
          self.loadingFile = false
          return
        }
        var reader = new FileReader()
        reader.onload = function (event) {
          self.loadingFile = false
          self.strTxtData = event.target.result
        }
        reader.readAsText(files[0])
      }
    },
  }
</script>
